$color: yellow;
$background: #ffffff;
$navy: #072c62;
$blue: #1da8e0;
$header: #f4f6f8;
$links: #f4f4f4;
$gray: #f4f6f8;
$gray-text: #777777;
$red: #cb1f44;
$pipe: #d8dadb;
$black: #151515;
$white: rgba(255,255,255,.87);

$danger: #ff4444;
$danger-dark: #cc0000;

$success:#a9dfbf;
$success-dark:#7dcea0;

$global-font: Roboto, "Helvetica Neue", sans-serif;
$accent: #C3D1DE;

$global-font: futura-pt, sans-serif;


/// Block Modifier
/// @access public
/// @param {String} $modifier - Modifier's name
@mixin modifier($modifier) {
    &--#{$modifier} {
        @content;
    }
}
