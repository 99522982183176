@import "settings";

$darkblue: #072c62;
$black: #010914;
$g10: #eeeeee;
$g20: #e3e4e4;
$g30: #d9dadb;
$g40: #cdcece;

.loading {
  &__banner {
    position: relative;
    max-height: 550px;
    min-height: 310px;
    width: 100%;
    text-align: left;
    color: $background;
    background: linear-gradient(to bottom, $darkblue 0%, $black 100%);
    font-family: $global-font;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(1, 9, 20, 0.85) 100%
      );
      z-index: 1;
    }
  }

  &__block {
    background-color: $g30;
    width: 100%;
    aspect-ratio: 16 / 9;
    display: grid;
    place-items: center;
  }

  &__content {
    position: absolute;
    bottom: 3rem;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2;
    margin: 0px auto;
    padding: 0 20px;

    @media screen and (max-width: 600px) {
      bottom: 1rem;
      left: 0;
      right: 0;
      width: 100%;
      margin: 0px auto;
      padding: 0 1.25rem;
    }
  }

  &__cta {
    background-color: $gray;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    margin: 1rem 0rem;
    flex-direction: column;
  }

  &__pg {
    margin: 1rem 0;
  }

  &__spinner {
    height: 40px;
    width: 40px;
    margin: 0 auto;

    @media screen and (min-height: 768px) {
      height: 60px;
      width: 60px;
    }
  }

  &__text-line {
    height: 2rem;
    width: 100%;
    margin-bottom: 1rem;
    background-color: $g20;
    opacity: 0.67;

    &--h1 {
      height: 4rem;
    }

    @media screen and (min-width: 768px) {
      width: calc(50% - 10px);
    }
  }

  &__wrapper {
    max-width: 1200px;
    margin: 1rem auto;
  }

  .hotlink {
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap:10px;
    margin: 2rem 0rem 1rem;

    &__card {
      position: relative;
      display:grid;
      grid-template-areas: "card";
      border-radius:10px;
      overflow: hidden;
      cursor: pointer;
      line-height: 0;
    }

    &__container {
      display:grid;
      grid-gap:10px;
      grid-template-areas:"cicon title icon";
      grid-template-columns: 75px 1fr 40px;
      align-items:center;

      img {
        grid-area:cicon;
        width:100%;
      }

      mat-icon {
        grid-area: icon;
      }
    }

    &__image {
      grid-area: card;
      border-radius:10px;
      overflow: hidden;
      height: 100%;
    }

    &__title {
      grid-area:title;
    }

    &__wrapper {
      display:grid;
      grid-area: card;
      z-index: 2;
      padding:2rem;
      align-items:end;
      color:$white;
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
      transition: background 333ms ease-in-out;

      &:hover {
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.35) 100%);
        transition: background 333ms ease-in-out;
      }
    }
  }


  .boxed-side {
    max-width: 1200px;
    margin: 3rem auto;
    font-family: $global-font;
    display: flex;
    justify-content: space-evenly;
    border-radius: 10px;
    overflow: hidden;

    @media screen and (max-width: 1240px) {
        margin: 3rem 0rem;
    }

    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        margin: 1rem 0rem;
    }

    h3 {
        margin-block-end: 0.3rem;
    }

    &__asset {
        width: 100%;
        height: 100%;
        object-fit: cover;
        order: inherit;
    }

    &__button {
        display: block;
        background-color: $blue;
        color: $background;
        padding: 0.75rem;
        border-radius: 3px;
        margin: 1rem 0rem 1.5rem;
        max-width: 80px;
        text-align: center;
        font-family: $global-font;
        font-weight: 500;
        font-size: 16px;
        border: 1px solid $blue;

        &:hover {
            background-color: $background;
            color: $blue;
        }
    }

    &__content {
        flex-basis: 100%;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        background-color: $gray;
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 24px;
        order: inherit;

        @media screen and (min-width: 1000px) {
            padding: 4rem;
            max-width: 50%;
        }

        @media screen and (min-width: 768px) and (max-width: 1000px) {
            max-width: 50%;
            padding: 3rem;
        }

        @media screen and (max-width: 768px) {
            order: 2;
        }
    }

    &__media {
        flex-basis: 100%;

        @media screen and (max-width: 768px) {
            order: 1;
        }
    }
  }

  .deals {
    box-shadow: none;
    border-radius:10px;
    font-family: $global-font;
    margin: 2em auto;
    overflow: hidden;
    background-color:$gray;
    cursor: pointer;
    max-width: 1200px;

    h5 {
      font-weight: 450;
      margin-block-start: 0.75em;
      margin-block-end: 0em
    }

    p {
      margin-block-start: 0em;
    }

    @media screen and (min-width:1200px) {
      margin:2rem auto;
    }
    @media screen and (min-width:500px) {
      display:grid;
      grid-template-columns: 1fr 2fr;
    }

    &__asset {
      height:100%;
    }

    &__container {
      margin: 0 1rem;

      @media screen and (min-width:1200px) {
        margin: 0px auto;
      }
    }

    &__content {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 29px 31px 29px 24px;
    }
  }

  .hero__card span.loading__icon, .hotlink__wrapper span.loading__icon {
    background-color:$g20;
    height: 3rem;
    margin:0 1rem 0 0;
    opacity: .67;
    width: 3rem;
  }

  .hero__card span.loading__text-line, .hotlink__wrapper span.loading__text-line {
    margin-bottom: 0;
  }
}
