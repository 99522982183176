/* You can add global styles to this file, and also import other style files */
@import '~normalize.css';
@import 'loading';
@import 'settings';

* {
  box-sizing: border-box;
}

app-root {
  display:block;
}

html, body {
  height:100%; // scroll top needs an adjustment because this is necessary for layout
  font-family: $global-font;
  font-size: 18px;
}

a {
  text-decoration: none;
  font-family: $global-font;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: $blue;
}

p {
  line-height: 1.45em;
}

img {
  max-width:100%;
  object-fit: cover;
}

.mat-button-toggle-standalone, .mat-button-toggle-group {
  padding: 0.25rem 0.5rem 0.25rem 0rem;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard, .mat-button-toggle-group-appearance-standard {
  border: 0px!important;
}

.mat-button-toggle-button {
  background-color: $background;
  border: 2px solid $blue!important;
  border-radius: 8px!important;
  margin: 0rem 0.5rem 0.5rem 0rem!important;
  height: 30px!important;
  display: flex!important;
  align-items: center!important;
  justify-content: center!important;

  &:hover {
      background-color: $blue!important;
      color: $background!important;
  }
}

.mat-checkbox-frame {
  border: 1px solid $blue!important;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: black!important;
}

.mat-button-toggle-checked {
  background-color: $blue!important;
  color: $background!important;
}

.mat-slide-toggle-bar {
  background-color: white!important;
  color: $background;
  border: 2px solid $blue;
  height: 22px!important;
  border-radius: 20px!important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color:$navy  !important;
}

.mat-slide-toggle-thumb {
  box-shadow: none!important;
  background-color: $blue!important;
  margin: 5px 1px 0px 1px;
  height: 14px!important;
  width: 14px!important;
}

.mat-list-base .mat-list-item .mat-list-item-content, .mat-list-base .mat-list-option .mat-list-item-content {
  padding: 0px!important; // not working anywhere else
}

.mat-snack-bar-container { // not working from child scss file
  background: #F4F6F8!important;
  max-width: 1440px!important;
  min-width: 80vw!important;
  color: $black!important;
  // font-family: $global-font!important;
  // box-shadow: none!important;
  border-left: 23px solid $red!important;
  margin: 3rem 24px 24px!important;
  // height: 60px!important;
  display: flex!important;
  justify-content: center;
  align-items: center;
  position: relative;

  @media screen and (min-width: 650px) {
    & div:first-child {
      width: 100%;
      max-width: 800px;
    }
  }

  @media screen and (max-width: 1000px) {
    font-size: 16px;
    justify-content: flex-start;
  }

  a {
    color: $blue;
    display: inline-block;
    font-family: $global-font;
    font-size: 15px;
    padding-left: 1rem;

    @media screen and (max-width: 1000px) {
      font-size: 16px;
      padding: 5px 0 0;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

button.notification__button {
  background: $blue;
  color: $background;
  cursor: pointer;
  border: 1px solid $blue;
  border-radius: 15px;
  height: 20px;
  width: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  position: absolute;
  right: 1rem;

  &:hover {
    color: $blue;
    background: $background;
  }

  @media screen and (max-width: 1000px) {
    top: 40%;
    cursor: pointer;
  }
}

div.notification {
  display: block;
  width: 95%;

  &__link {
    min-width: 80px;
    // white-space: nowrap;
    width: 25%;

    @media screen and (max-width: 1000px) {
      width: 100%;
    }
  }

  &__text {
    @media screen and (max-width: 1000%) {
      max-width: 89%;
    }
  }

  @media screen and (min-width: 1000px) {
    display: flex;
    justify-content: space-between;
  }
}

.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $blue!important;
}

.mat-drawer-inner-container::-webkit-scrollbar {
  display: none!important;
}

.mat-expansion-panel-body {
  padding: 1rem 2rem 0rem!important;

  @media screen and (max-width: 768px) {
    padding: 0rem 1rem 0rem!important;
  }
}

.mat-drawer-container {
  background-color: inherit!important;
}

.mat-expansion-indicator::after {
  display: none!important;
}

.mat-form-field {
  width: 100%;
}

.mat-form-field-flex.mat-form-field-flex {
  background-color: $gray;
  box-shadow: 0px 2px 0px #072C62;
  border:0;
  padding: 0.35em 0.75em;

  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.8em) scale(0.75) perspective(100px) translateZ(0.001px) translateX(-0.9em) !important;
}

.mat-form-field-underline {
  display: none;
}

.mat-form-field-label-wrapper {
  overflow: visible !important;
}

.mat-select-arrow.mat-select-arrow {
  color: $blue;
  padding: 12px;
  border-top: 0;
  background-image: url('../src/assets/icons/arrow_down.svg');
}

.mat-primary.mat-primary  .mat-option.mat-selected:not(.mat-option-disabled).mat-option.mat-selected:not(.mat-option-disabled){
  color: $black;
  font-size: 18px;
  font-family: $global-font;
}

.mat-option-text.mat-option-text {
  font-size: 18px;
  font-family: $global-font;
}

// .mat-select-value-text.mat-select-value-text {
//   padding-left: 0.75rem;
// }

.mat-expansion-indicator::after {
  display: none!important;
}

.mat-form-field-infix.mat-form-field-infix {
  border-top: 0;
}

.mat-select-value.mat-select-value.mat-select-value.mat-select-value {
  padding-top: 4px;
}

.cdk-focused {
  background-color: none!important;
}

.cdk-program-focused {
  background-color: none!important;
}

.mat-button-toggle-focus-overlay {
  display: none!important;
}


.about .body-copy {
  margin: 1.5rem 25px;

  @media screen and (min-width: 800px) {
    margin: 3rem auto;
  }
}

.covid, .deals, .diversity, .directory .directory__banner, .download, .event, .meal-deal, .merchant, .profile, .promotions, .request, .schedule, .training {
  .top .top__container {
    max-height: 310px;

    .top__content {
      max-width: 100%;
      padding: 0 25px;
    }

    .top__image {
      max-height: 310px;
    }
  }
}

.team .deals {
  .deals__title {
    margin-bottom: 0;
  }
}

.body-copy__button {
  background-color: #007FEB;
  color: $background;
  max-width: 200px;
  padding: 0.75rem;
  text-align: center;
  display: block;
  margin: 1rem 0rem 0rem;

  a {
    color: $background;
    font-weight: 515;
  }
}

table {
  width:100%;
}


.body-copy {
  margin: 1.5rem 25px;

  @media screen and (min-width: 1200px) {
    margin: 3rem auto;
  }

  h3, h4 {
    font-weight: 450;
  }

  ul {
      padding-inline-start: 10px;
      list-style: none;
      margin-block-start: -0.5em;
      margin-block-end: -0.5em;
      li::before {
          content: "\2022";
          color: $blue;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin: 1px 5px 0px -1rem;
          padding: 10px 0px;

          @media screen and (max-width: 800px) {
            margin: 1px 5px 0px -0.5rem;
          }
      }
  }

  ol {
      counter-reset: item;
      padding-inline-start: 10px;
  }

  ol li {
      display: block;
      padding: 10px 0px;
  }

  ol li:before {
      content: counter(item) ". ";
      counter-increment: item;
      color: $blue;
      margin: 1px 5px 0px -1rem;


      @media screen and (max-width: 800px) {
        margin: 1px 5px 0px -0.5rem;
      }
  }

  p {
      line-height: 1.45em;
      max-width: 800px;
  }
}
